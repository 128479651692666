/* TODO: insert default styles of your component here for easy access */

.ms-menu-list{
   list-style:none;
   padding: 0;
   width: 100%;
}

.hamburger {
   cursor : pointer;
}

.ms-menu-item{
   white-space : nowrap;
   user-select : none;
   overflow : hidden;
   align-items : center;
   padding-left : 15px;
   justify-content: left;
   height: 50px;
   cursor: pointer;
   display: flex;
   color : #424242;
   font-size : 1.3em;
}


.ms-menu-item:hover{
   background-color: #ccc;
}

.ms-menu-item-selected {
   background : #ddd;
}

.ms-header-menu{
   align-items: center;
   justify-content: center;
   display: flex;
   margin-left: 10px;
   font-size: 25px;
}

.ms-user-display-container {
   position : fixed;
   right : 0px;
   margin-right : 10px;
}

.ms-user-display {
   cursor: pointer;
   height : 40px;
}
.ms-user-display div {
   font-size : 15px;
   align-items : center;
}
.ms-user-display .user {
   margin-left : 15px
}

.ms-container {
   display: flex;
   flex: 1;
   flex-direction: column;
}

.ms-header {
   background-color: #fff;
   border-bottom : 1px solid #ccc;
   display: flex;
   flex-direction: row;
}

.ms-body {
   display: flex;
   flex: 1;
   flex-direction: row;
}

.ms-menu {
   display: flex;
   width : 20%;
   transition : 0.2s;
   flex-direction: column;
   border-right : 1px solid #ddd;
}

.ms-menu-hidden {
   display : flex;
   overflow : hidden;
   width : 0px;
   transition : 0.2s;
   border-right : 1px solid #ddd;
}


.ms-body-container {
   width: 100%;
   min-width: 10%;
   height: 100%;
   display: flex;
   flex-direction: column;
}

